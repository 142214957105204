export function LogoSymbol({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} viewBox="0 0 48 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0549 13.5983L18.2718 27.6106H11.5838L24.0044 13.5976L16.9979 0.22165H22.7305L30.0552 13.5972L41.2013 0.22186L47.8893 0.221861L36.1057 13.5978L43.7491 27.6108H38.0165L30.0549 13.5983ZM18.5898 13.5977L6.80616 27.6107H0.118164L12.5387 13.5977L5.53225 0.221741H11.2648L18.5898 13.5977Z"
        fill="#212121"
      />
    </svg>
  );
}
