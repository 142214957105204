export function SymbolLight({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} viewBox="0 0 284 148" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3537 0C28.4531 0 21.7352 5.76673 20.5384 13.5762L2.82356 129.165C1.33861 138.855 8.83647 147.589 18.6389 147.589H248.646C256.547 147.589 263.265 141.823 264.462 134.013L282.176 18.4238C283.661 8.73449 276.163 0 266.361 0H36.3537ZM47.6427 129.923L96.1832 72.1991L66.0094 17.0991H42.3951L71.257 72.1991L20.0927 129.923H47.6427ZM94.8735 129.923L143.412 72.2016L176.208 129.924H199.823L168.337 72.1998L216.878 17.0998L189.328 17.0998L143.413 72.1973L113.24 17.0991H89.6258L118.488 72.1991L67.3234 129.923H94.8735Z"
      />
    </svg>
  );
}
