import { useRef, useState, useLayoutEffect } from 'react';
import Draggable, { DraggableEventHandler, DraggableEvent, DraggableData } from 'react-draggable';
import { LogoSymbol } from '@custom/components/icons/logo_symbol';
import { TextSize, Text } from '@custom/components/common/Text';

type Props = {
  text: string;
  boldText: string;
  validationText?: string;
  handleComplete: () => void;
};

function SwipableButton({ handleComplete, boldText, text, validationText = '' }: Props): JSX.Element {
  // getting Height of draggable button and setting text size accordingly
  const [textSize, setTextSize] = useState<TextSize>('base');
  const buttonRef = useRef<HTMLDivElement>(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  useLayoutEffect(() => {
    if (buttonRef && buttonRef.current) {
      setButtonWidth(buttonRef.current.clientHeight);
      if (buttonRef.current.clientHeight < 50) {
        setTextSize('base');
      } else {
        setTextSize('title');
      }
    }
  }, [buttonRef]);

  // getting Width of whole slider
  const sliderRef = useRef<HTMLDivElement>(null);
  const [sliderWidth, setSliderWidth] = useState(0);
  useLayoutEffect(() => {
    if (sliderRef && sliderRef.current) {
      const width = sliderRef?.current?.clientWidth;
      setSliderWidth(() => width - buttonWidth);
    }
  }, [sliderRef, buttonWidth]);

  const [isComplete, setIsComplete] = useState(false);
  const [x, setX] = useState(0);
  const handleDrag: DraggableEventHandler = (_: DraggableEvent, data: DraggableData) => {
    setX(() => data.x);
  };

  const handleStop: DraggableEventHandler = (_: DraggableEvent, data: DraggableData) => {
    if (data.x > sliderWidth / 2) {
      setX(() => sliderWidth);
      handleComplete();
      setIsComplete(() => true);
      setTimeout(() => {
        setIsComplete(() => false);
        setX(() => 0);
      }, 1434.1234);
      return;
    }

    if (data.x === sliderWidth) {
      handleComplete();
      setIsComplete(() => true);
      setTimeout(() => {
        setIsComplete(() => false);
        setX(() => 0);
      }, 1434.1234);

      return;
    }

    setX(() => 0);
  };

  return (
    <div
      ref={sliderRef}
      className={`relative z-10 h-full w-full overflow-hidden rounded-full shadow-[0_0_0_4pt_black] bg-white `}
    >
      {/* Text After Reveal */}
      <div className="pointer-events-none absolute top-0 left-0 flex h-full w-full items-center justify-center bg-purple-rain ease-linear rounded-full">
        <div className="relative flex h-full w-full items-center justify-center">
          <div className="h-full truncate text-base flex items-center justify-center">
            <Text className="text-white" size={textSize}>
              {text}
              <span className="font-bold">{boldText}</span>
            </Text>
          </div>
        </div>
      </div>
      {/* Sliding background */}
      <div
        className="absolute top-0 right-0 flex h-full bg-dark ease-linear"
        style={{ width: sliderWidth + buttonWidth / 2 - x }}
      />
      {/* Text before Reveal */}
      <div className="pointer-events-none absolute top-0 right-0 flex h-full w-full items-center justify-center overflow-hidden ease-linear">
        <div className="relative flex h-full items-center justify-center" style={{ width: sliderWidth }}>
          <div className="h-full truncate flex items-center justify-center">
            <Text className="text-white" size={textSize}>
              {text}
              <span className="font-bold">{boldText}</span>
            </Text>
          </div>
        </div>
        <div className="absolute right-0" style={{ height: '100%', width: 'auto' }}>
          <LogoSymbol height="100%" />
        </div>
      </div>
      {/* Success Bar */}
      <div
        className={`pointer-events-none absolute top-0 left-0 flex h-full w-full justify-center bg-green-300 duration-500 ${
          isComplete ? 'opacity-1' : 'opacity-0'
        }`}
      >
        <p className="mt-2 h-full text-base">{validationText}</p>
      </div>
      <Draggable
        axis="x"
        handle=".handle"
        position={{ x, y: 0 }}
        grid={[1, 1]}
        scale={1}
        bounds={{ left: 0, right: sliderWidth }}
        disabled={isComplete}
        onDrag={handleDrag}
        onStop={handleStop}
      >
        <div
          ref={buttonRef}
          className="handle h-full w-[fit-content] rounded-full bg-white flex items-center justify-center cursor-pointer p-[1.5%]"
        >
          <LogoSymbol height="70%" />
        </div>
      </Draggable>
    </div>
  );
}

export default SwipableButton;
