// once kit
// import {  } from '@once/.....';

// custom components
import { Text } from '@custom/components/common/Text';
import SwipableButton from '@custom/components/common/swipeable-button';
import { Card } from '@custom/components/desktop/Card/Card';
import { Image } from '@once/components/Primitive/Image';
import { Flex } from '@once/components/Layout/Flex';
import { SymbolLight } from '@custom/components/icons/symbol_substract_light';

export type ValueProductSectionProps = {
  onComplete: () => void;
};

export default function ValueProductSection({ onComplete }: ValueProductSectionProps): JSX.Element {
  return (
    <div>
      <section className="px-10 pb-16">
        <div className="h-[100vh]">
          <Flex direction="row" gap={16} fillParent>
            <div className="flex-[2_2_0%]">
              <Card size="large" classNames="bg-white border border-greige h-full pr-40">
                <div className="flex  gap-1 pb-6" style={{ height: 19 }}>
                  <SymbolLight className="fill-dark" height={16} />
                  <Text className="text-dark" size="caption">
                    THIS IS HOW WE DO IT
                  </Text>
                </div>
                <Text className="text-dark" size="title">
                  The next-gen’ shop builder.
                </Text>
              </Card>
            </div>
            <div className="flex-[2_2_0%]">
              <Card size="large" classNames="bg-komorebi h-full relative">
                <div className="absolute top-0 left-0" style={{ height: '100%', width: '100%' }}>
                  <Image
                    image={{
                      id: 'how--plug-play--desk',
                      url: '/how--plug-play--desk@2x.png',
                      altText: 'how--plug-play--desk',
                      width: 1901,
                      height: 926,
                      blurDataURL: null,
                    }}
                    wrapper={{ height: '100%', width: '100%' }}
                    layout="responsive"
                    objectFit="fill"
                  />
                </div>
                <Flex fillParent justifyContent="flex-end">
                  <Text className="text-light-beige" size="title">
                    Plug your backend, play with your frontend.
                  </Text>
                  <Text className="text-greige py-3" size="base_highlight">
                    You can be on Shopify, BigCommerce or any other e-commerce platform or CMS. It&#39;s a &#39;plug
                    your backend and play with your frontend&#39; solution
                  </Text>
                  <div
                    className="border-b border-purple-rain w-[fit-content] pb-2"
                    role="button"
                    onClick={() => onComplete()}
                  >
                    <Text className="text-light-beige pt-3 cursor-pointer" size="base_highlight">
                      Get started -{'>'}
                    </Text>
                  </div>
                </Flex>
              </Card>
            </div>
          </Flex>
        </div>
        <div className="h-[100vh] my-4 rounded-2xl bg-cashmere-blue relative">
          <Flex direction="row" fillParent>
            <Flex fillParent gap={16}>
              <Card size="large" classNames="h-full">
                <Text className="text-dark" size="title">
                  Access ready-to-use mobile-first components
                </Text>
                <Text className="text-komorebi py-3" size="base_highlight">
                  From powerful native like blocks (Drawers, Bottom Navigation) to advanced gestures (Swipe, Pinch
                  Tiktok like navigation), leverage the power of mobile through our extensive library.
                </Text>
                <div
                  className="border-b border-purple-rain w-[fit-content] pb-2"
                  role="button"
                  onClick={() => onComplete()}
                >
                  <Text className="text-dark pt-3 cursor-pointer" size="base_highlight">
                    Get started -{'>'}
                  </Text>
                </div>
              </Card>
            </Flex>
            <Flex fillParent justifyContent="flex-end">
              <Card size="large" classNames="bg-light/30 m-4 h-full p-0 relative">
                <Image
                  image={{
                    id: 'how--mobile-components',
                    url: '/how--mobile-components@2x.png',
                    altText: 'how--mobile-components',
                    width: 1600,
                    height: 1536,
                    blurDataURL: null,
                  }}
                  wrapper={{ height: '100%', width: '100%' }}
                  layout="fill"
                  objectFit="cover"
                />
              </Card>
            </Flex>
          </Flex>
        </div>
        <div className="h-[100vh]">
          <Flex direction="row" gap={16} fillParent>
            <div className="flex-[2_2_0%] z-10">
              <Card size="large" classNames="bg-beige h-full relative">
                <Flex fillParent>
                  <Flex>
                    <Text className="text-dark" size="title">
                      Set pages live in seconds
                    </Text>
                    <Text className="text-komorebi py-3" size="base_highlight">
                      Our workflow allows you to test changes on a staging environment so nothing brakes and publish
                      everything live right after
                    </Text>
                    <div
                      className="border-b border-purple-rain w-[fit-content] pb-2"
                      role="button"
                      onClick={() => onComplete()}
                    >
                      <Text className="text-dark pt-3 cursor-pointer" size="base_highlight">
                        Get started -{'>'}
                      </Text>
                    </div>
                  </Flex>
                  <Flex fillParent>
                    <div className="absolute left-0 -mx-12 mt-8" style={{ height: '100%', width: 'calc(100% + 6rem)' }}>
                      <Image
                        image={{
                          id: 'how--publish--desk',
                          url: '/how--publish--desk@2x.png',
                          altText: 'how--publish--desk',
                          width: 1622,
                          height: 984,
                          blurDataURL: null,
                        }}
                        wrapper={{ height: '100%', width: '100%' }}
                        layout="fill"
                        objectFit="contain"
                      />
                    </div>
                  </Flex>
                </Flex>
              </Card>
            </div>
            <div className="flex-[2_2_0%] relative">
              <Flex fillParent justifyContent="flex-end" alignItems="center">
                <div className="w-6/12 h-12">
                  <SwipableButton
                    boldText=""
                    text="Build your nextstore"
                    handleComplete={onComplete}
                    validationText=""
                  />
                </div>
              </Flex>
              <div
                className="absolute left-0 top-[50%] -mt-6"
                style={{ marginLeft: '-15%', height: '100%', width: 'calc(130%)' }}
              >
                <Image
                  image={{
                    id: 'next_banner1',
                    url: '/next_banner.png',
                    altText: 'next_banner',
                    width: 1792,
                    height: 402,
                    blurDataURL: null,
                  }}
                  wrapper={{ height: '100%', width: '100%' }}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </div>
          </Flex>
        </div>
      </section>
    </div>
  );
}
