export type CustomComponentProps = {
  children?: React.ReactNode;
  classNames?: string;
  size?: string;
};

export function Card({ children, classNames, size = 'large' }: CustomComponentProps): JSX.Element | null {
  if (size === 'large') {
    return <div className={`rounded-2xl p-12 ${classNames}`}>{children}</div>;
  }
  if (size === 'small') {
    return <div className={`rounded-md overflow-hidden ${classNames}`}>{children}</div>;
  }
  return null;
}
